@import url(https://fonts.googleapis.com/css?family=Saira+Condensed&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Saira+Condensed&display=swap&subset=latin-ext);
.styles_customNavbar__2WebC {
    background-color: transparent;
    overflow: hidden;
}

.styles_customNavbar__2WebC h1 {
    margin-right: 0;
}

.styles_customNavbar__2WebC .styles_navbarButton__3Kwh7 {
    border: none;
    outline: none;
}

.styles_customNavbar__2WebC span {
    font-size: 1.7rem;
    font-family: Tenby-five;
}

.styles_websiteTitle__5IGDV {
    margin: 0;
    font-size: 2.6rem;
    font-weight: 900;
    font-family: Tenby-five;
}

.styles_websiteTitle__5IGDV .styles_dot__2ziKQ {
    color: #3eafad;
    font-size: 2.4rem;
}

.styles_customNavlink__3pPFQ:focus,
.styles_customNavlink__3pPFQ:hover,
.styles_customNavlink__3pPFQ:visited,
.styles_customNavlink__3pPFQ:link {
  text-decoration: none;
}

.styles_customNavlink__3pPFQ span {
  transition: 0.3s;
}

.styles_customNavlink__3pPFQ h1 > span {
  font-size: 2.4rem;
}

.styles_customNavlink__3pPFQ:link.styles_activated__2KW4G span {
  color: #fff;
  background-color: #3eafad;
  -webkit-transform: skew(-12deg);
          transform: skew(-12deg);
}

.styles_customNavlink__3pPFQ:link.styles_activated__2KW4G span:hover {
   color: #fff;
}

@media screen and (max-width: 992px) {
 .styles_customNavlink__3pPFQ:link.styles_activated__2KW4G span {
    -webkit-transform: none;
            transform: none;
  }
}




.styles_flagContainer__CuBMc {
    border-radius: 5px;
    padding: inherit;
    margin-left: 15px;
    margin-right: auto;
    cursor: pointer;
}

.styles_flag__3r7WB {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.styles_flag__3r7WB:hover {
    box-shadow: 0 0 2px 0px #fff;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.styles_animatedSlider__13Xy1 {
  overflow: hidden;
}

.styles_imageContainer__3vlcJ {
  height: 65vh;
  width: calc(100 * 100vw);
  background-image: url(/static/media/slider2X_750px.a83f83f8.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-attachment: local;
  -webkit-animation: styles_slide__1josu 3600s linear infinite;
          animation: styles_slide__1josu 3600s linear infinite;
}

@-webkit-keyframes styles_slide__1josu {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes styles_slide__1josu {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.styles_singleCard__1orzR {
  background-color: rgba(60, 65, 68);
  opacity: 0.8;
  transition: 0.8s;
  cursor: pointer;
}

.styles_singleCard__1orzR:hover {
  opacity: 1;
}

.styles_cardText__1ohKy {
  background-color: transparent;
}

.styles_cardHeader__NZzuk {
  position: relative;
}

.styles_cardHeader__NZzuk h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.styles_cardEven__2qeV4 {
  color: #ffffff;
  background-color: rgba(62, 175, 173);
}

.styles_cardWrapper__3LI50 {
  transition: 0.5s;
}

.styles_cardWrapper__3LI50:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

@media (max-width: 767px) {
  .styles_cardHeader__NZzuk {
    margin-bottom: 10vh;
    padding: 20vh 0;
  }
}

@media screen and (min-width: 1025px) {
  .styles_cardWrapper__3LI50:hover {
    -webkit-transform: scale(1.02) skew(-5deg);
            transform: scale(1.02) skew(-5deg);
  }
}


.styles_mainPageContainer__382i6 > h1 {
    font-size: 3.2rem;
    font-weight: bold;
}

.styles_fontIcon__a8TXg {
  font-size: 2rem;
  color: #fff;
  transition: 0.2s;
}

.styles_fontIcon__a8TXg:hover {
      color: #ddd;
}

@media screen and (min-width: 1024px) {
    .styles_fontIcon__a8TXg {
      font-size: 2.5rem;
  }
}

.styles_valueArticle__3R7dt {
  font-size: 1.2rem;
};

.styles_aboutPageContainer__3z03O {
  color: #fff;
}

.styles_workManImg__3Y5iK {
  width: 100%;
}

.styles_fontIcon__7GR6L {
  font-size: 2rem;
}

@media screen and (min-width: 1024px) {
  .styles_fontIcon__7GR6L {
    font-size: 2.5rem;
  }
}

.styles_projectsPageContainer__1XttU {
  position: relative;
  z-index: 2;
}


.styles_label__1a-X- {
  cursor: pointer;
}

.styles_buttonsPanel__2S1_C input {
  color: #fff;
  background-color: #3eafad;
  transition: 0.2s;
}

.styles_buttonsPanel__2S1_C input:hover,
.styles_buttonsPanel__2S1_C input:focus,
.styles_buttonsPanel__2S1_C input:active {
  color: #ddd;
  background-color: #329390;
}

.styles_modelsContainer__1n8lS {
  color: #fff;
  background-color: #32373a;
  font-family: 'Saira Condensed', sans-serif;
  overflow-x: hidden;
}

.styles_modelsContainer__1n8lS h1 {
  font-size: 3rem;
}

.styles_modelsContainer__1n8lS h2 {
  font-size: 2.2rem;
}

.styles_modelsContainer__1n8lS p {
  font-size: 1.2rem;
  text-align: justify;
}

.styles_modalDialog__2zBGo {
  display: table;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  pointer-events: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.styles_modalDialog__2zBGo .styles_modalContent__3vTR0 {
  width: inherit;
  max-width: inherit;
  margin: 0 auto;
  color: #fff;
  background-color: #32373a;
  pointer-events: all;
}

.styles_modalDialog__2zBGo .styles_rodoCompButton__3DYfh {
  color: #fff;
  background-color: #3eafad;
  transition: 0.2s;
}

.styles_modalDialog__2zBGo .styles_rodoCompButton__3DYfh:hover,
.styles_modalDialog__2zBGo .styles_rodoCompButton__3DYfh:focus,
.styles_modalDialog__2zBGo .styles_rodoCompButton__3DYfh:active {
  color: #ddd;
  background-color: #329390;
}

.styles_modalDialog__2zBGo .styles_closeSign__3ZyHd {
  color: #fff;
}

.styles_modalDialog__2zBGo .styles_closeSign__3ZyHd:hover {
  color: #ddd;
}


.styles_rodoContentContainer__1-ARU {
  text-align: left;
}

.styles_rodoContentContainer__1-ARU span,
.styles_rodoContentContainer__1-ARU ol {
  font-size: 1.2rem;
}

.styles_rodoLink__11zlN {
  font-weight: 700;
  color: #3eafad;
 }

.styles_rodoLink__11zlN:hover,
.styles_rodoLink__11zlN:active,
.styles_rodoLink__11zlN:visited {
    color: rgb(50, 147, 144);
}




.styles_modalDialog__1Yd-S {
  display: table;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  pointer-events: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.styles_modalDialog__1Yd-S .styles_modalContent__1VxJG {
  width: inherit;
  max-width: inherit;
  margin: 0 auto;
  color: #fff;
  background-color: #32373a;
  pointer-events: all;
}

.styles_modalDialog__1Yd-S .styles_closeSign__2jwIZ {
  font-size: 1.5rem;
  color: #fff;
}

.styles_modalDialog__1Yd-S .styles_closeSign__2jwIZ:hover {
  color: #ddd;
}

.styles_privacyContainer__26ejg {
  max-height: 50vh;
  overflow-x: auto;
  padding-right: 10px;
}
.styles_modalDialog__1Yd-S .styles_rodoCompButton__3RCIX {
  color: #fff;
  background-color: #3eafad;
  transition: 0.2s;
}

.styles_modalDialog__1Yd-S .styles_rodoCompButton__3RCIX:hover,
.styles_modalDialog__1Yd-S .styles_rodoCompButton__3RCIX:focus,
.styles_modalDialog__1Yd-S .styles_rodoCompButton__3RCIX:active {
  color: #ddd;
  background-color: #329390;
}




.styles_rodoContainer__dZnVr {
    font-size: 1.2rem;
    font-family: Tenby-five;
}

.styles_rodo__3wP3E {
    cursor: pointer;
    transition: 0.2s;
}

.styles_rodo__3wP3E:hover {
    color: #ddd;
}





* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 15px;
    background-color: #32373a;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #3eafad;
    border-radius: 10px;
}

.main-container {
    min-height: 100vh;
    color: #fff;
    background-color: #32373a;
    font-family: 'Saira Condensed', sans-serif;
    overflow-x: hidden;
}

.main-container h1 {
    font-size: 3rem;
}

.main-container h2 {
    font-size: 2.2rem;
}

.main-container p {
    font-size: 1.2rem;
    text-align: justify;
}

