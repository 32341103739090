.rodoContainer {
    font-size: 1.2rem;
    font-family: Tenby-five;
}

.rodo {
    cursor: pointer;
    transition: 0.2s;
}

.rodo:hover {
    color: #ddd;
}




