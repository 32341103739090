@import url('https://fonts.googleapis.com/css?family=Saira+Condensed&display=swap&subset=latin-ext');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 15px;
    background-color: #32373a;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #3eafad;
    border-radius: 10px;
}

.main-container {
    min-height: 100vh;
    color: #fff;
    background-color: #32373a;
    font-family: 'Saira Condensed', sans-serif;
    overflow-x: hidden;
}

.main-container h1 {
    font-size: 3rem;
}

.main-container h2 {
    font-size: 2.2rem;
}

.main-container p {
    font-size: 1.2rem;
    text-align: justify;
}
